import { Component } from 'react';
import { Link } from 'react-router-dom';
import AnimatedPage from './AnimationLayout';
import Loader from './load';
import { useState } from 'react';

export class InfoPage extends Component {
  // Render method.
  render() {
    return (
      <AnimatedPage>
        <div id='full'>
          <div id='cornerButton'>
            <Link to='/' id='buttonText'>
              GAME
            </Link>
          </div>

          <section id='centerSection'>
            <section id='topText'>
              <h1> I am Aidan.</h1>
              <h2> I make games.</h2>
            </section>
            <p id='mainText'>
              You can learn more about me{' '}
              <a href='https://www.aidanmstrong.com/' id='online-user-label'>
                here.
              </a>
            </p>
          </section>
        </div>
      </AnimatedPage>
    );
  }
}
